<template>
  <div id="layout-default" class="container-fluid p-0">
    <nav class="navbar navbar-expand-md navbar-dark bg-dark flex-nowrap">
      <router-link
        class="navbar-brand group-logo p-3 border-default-dark border-right"
        :to="{ name: 'landing' }"
        v-if="user && user.username == 'infrasel@semesta.com.my'"
      >
        <img
          src="@/assets/images/infrasel-logo-white.png"
          class=""
          alt="InfraSEL"
        />
        <p class="mb-0 mt-n1 ml-2 text-muted">Smart Mining Monitoring</p>
      </router-link>
      <router-link
        class="navbar-brand group-logo p-3 border-default-dark border-right"
        :to="{ name: 'landing' }"
        v-else-if="user && user.username.includes('@jupem.gov.my')"
      >
        <img src="@/assets/images/selgem-logo.png" class="" alt="SELGEM" />
        <p class="mb-0 mt-0 ml-2 text-muted">Smart Mining Monitoring</p>
      </router-link>
      <router-link
        class="navbar-brand group-logo bg-semitrans-light justify-content-center align-items-center p-3 shadow-sm"
        :to="{ name: 'landing' }"
        v-else-if="user && user.company == 'KLM'"
      >
        <img src="@/assets/images/logo-klm.png" class="" alt="KLM" />
        <p class="mb-0 text-default-dark text-center">
          Smart Mining Monitoring
        </p>
      </router-link>
      <router-link
        class="navbar-brand group-logo bg-semitrans-light justify-content-center align-items-center p-3 shadow-sm"
        :to="{ name: 'landing' }"
        v-else
      >
        <img
          src="@/assets/images/KSSBConsult.png"
          class=""
          alt="KSSB Consult"
        />
        <p class="mb-0 text-default-dark text-center">
          Smart Mining Monitoring
        </p>
      </router-link>
      <button
        class="navbar-toggler py-3 bg-default-dark"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <small>MENU</small>
      </button>
      <div
        class="collapse navbar-collapse bg-dark flex-grow-1"
        id="navbarSupportedContent"
      >
        <ul class="navbar-nav flex-lg-fill py-2 px-3">
          <li
            class="nav-item"
            v-for="link in navLinks"
            :key="`navLink-${link.name}`"
            :class="{ 'd-none': !link.show }"
          >
            <router-link :to="{ name: link.name }" class="nav-link">
              {{ link.label }}
            </router-link>
          </li>
        </ul>
      </div>
      <div class="mr-3 ml-auto d-flex justify-content-end align-items-center">
        <site-dropdown class="d-none d-sm-flex mr-3" />
        <user-dropdown />
      </div>
    </nav>
    <slot />
    <footer class="row mx-0 border-top border-dark bg-dark">
      <div class="col d-flex justify-content-start align-items-center">
        <img
          src="@/assets/images/powered-by-selgem.png"
          class="footer-logo"
          alt="Powered by SELGEM"
        />
      </div>
      <div class="col d-flex justify-content-end align-items-center">
        <p class="mb-0 small">&copy; SELGEM {{ new Date().getFullYear() }}</p>
      </div>
    </footer>
  </div>
</template>

<script>
import $ from "jquery";
import SiteDropdown from "@/components/SiteDropdown";
import UserDropdown from "@/components/UserDropdown";

export default {
  name: "layout-default",
  components: {
    SiteDropdown,
    UserDropdown,
  },
  data() {
    return {
      user: "",
    };
  },
  watch: {
    "$route.name": {
      handler() {
        const navbar = $("#navbarSupportedContent");

        navbar?.collapse("hide");
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    navLinks() {
      return [
        {
          name: "map",
          label: "Map",
          show: true,
        },
        {
          name: "dashboard",
          label: "Dashboard",
          show:
            this.user &&
            this.user.username !== "central-user" &&
            (this.user.username === "semesta-user" ||
              !this.user.sites ||
              this.user.sites.length == 0),
        },
      ];
    },
  },
  mounted() {
    const user = localStorage.getItem("user");

    this.user = user ? JSON.parse(user) : "";
  },
};
</script>